<template>
  <div>
    <div v-if="!showLoader">
      <m-page-title title="Customer List by Territory" />
      <div class="my-2 align-items-end row">
        <div class="flex-column flex-shrink-1 mr-2">
          <b-form-group
            id="territory-list"
            label="Territories"
            label-for="territory-select"
            class="field-label-form-group mt-2 mb-0">
            <b-form-select
              id="territory-select"
              v-model="selectTerritory"
              :options="filteredTerritoryList"
              value-field="territory_id"
              text-field="territory_name"></b-form-select>
          </b-form-group>
        </div>
        <div class="flex-column ml-4">
          <b-button
            size="md"
            class="mr-2"
            variant="info"
            :disabled="disableSearch"
            @click="generateXLSX()">
            Generate Report (XLSX)
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { utils, writeFileXLSX } from "xlsx";
import django_session from "@/api/django_session"

export default {
  name: "CustomerListReport",
  components: {},

  data() {
    return {
      territoryList: [],
      selectTerritory: null,
      customerListReport: [],
      customerListExport: [],
    }
  },

  computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader"]),

      disableSearch() {
        return !this.selectTerritory
      },

      filteredTerritoryList() {
        let list = this.territoryList
        if (this.roles.includes("SLS_REP")) {
          return list.filter((territory) => territory.sales_rep_id === this.user.user_id)
        }
        return list
      },
  },

  created() {

  },

  async mounted() {
    document.title = "Customer Territory Report"
      this.$store.dispatch("common/showLoader", true)
      await django_session
        .get("erp/customer/territory/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => {
          this.territoryList = response.data
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.$store.dispatch("common/showLoader", false)
        })
  },

  methods: {
    generateXLSX() {
      this.$store.dispatch("common/showLoader", true)
      django_session
        .get("erp/customer/list/", {
          params: {
            page_size: 0,
            territory: this.selectTerritory,
          },
        })
        .then((response) => {
          this.customerListReport = response.data
          // Parse the data to remove unwanted fields
          this.customerListExport = this.customerListReport.map((customer) => {
            return {
              "Customer Name": customer.customer_name,
              "Address": customer.address_1,
              "Address 2": customer.address_2,
              "City": customer.city,
              "Province": customer.province,
              "Postal Code": customer.postal_code,
              "Type": customer.customer_type,
            }
          })

          let columnNames = ["Customer Name", "Address", "Address 2", "City", "Province", "Postal Code", "Type"]
          let territoryName = this.territoryList.find(
            (territory) => territory.territory_id === this.selectTerritory
          ).territory_name
          let fileName = "Customer List_" + territoryName + ".xlsx"
          
          const workbook = utils.json_to_sheet(this.customerListExport)
          const export_file = utils.book_new()
          utils.book_append_sheet(export_file, workbook, territoryName)
          utils.sheet_add_aoa(workbook, [columnNames], { origin: "A1" })
          writeFileXLSX(export_file, fileName)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.$store.dispatch("common/showLoader", false)
        })
    }
  }
}
</script>