<template>
  <div class="">
    <div v-if="!showLoader">
      <m-page-title title="Rental Pump Invoicing" />
      <div class="my-2 align-items-end row">
        <div class="flex-column flex-shrink-1 mr-2">
          <b-form-group
            id="pump-type-list"
            label="Pump Types"
            label-for="pump-type-select"
            class="field-label-form-group mt-2 mb-0">
            <b-form-select
              id="pump-type-select"
              v-model="selectPumpType"
              :options="pumpTypes"
              value-field="pump_type_id"
              text-field="name"></b-form-select>
          </b-form-group>
        </div>
        <div class="flex-column flex-shrink-1 mr-2">
          <m-text-input
            id="to-date"
            label="As of Date"
            type="date"
            :value="asOfDate"
            :alternative="false"
            :group="false"
            name="To Date"
            @update-value="
              (input) => {
                asOfDate = input
              }
            " />
        </div>
        <div class="flex-column flex-shrink-1">
        </div>
        <div class="flex-column ml-4">
          <b-button
            size="md"
            class="mr-2"
            variant="info"
            :disabled="disableSearch"
            @click="getPumpReportData()">
            View Report
          </b-button>
          <b-button
            size="md"
            class="mr-2"
            variant="info"
            :disabled="disableSearch"
            @click="generateXLSX()">
            Generate Report (XLSX)
          </b-button>
          <b-button
            size="md"
            class="mr-2"
            variant="primary"
            @click="clear()">
            Clear
          </b-button>
        </div>
      </div>
      <div id="results-table">
        <b-table
          id="pump-list-table"
          responsive
          head-variant="dark"
          :items="pumpListReport"
          :fields="pump_list_fields"
          per-page="0">
        </b-table>
      </div>
      <div id="pdf-viewer">
        <pdf-viewer
          :file-url="fileUrl"
          file-name="Rental Pump Invoicing.pdf"
          @pdf-ready="watchPdfComponent($event)" />
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  import PdfViewer from "@/components/PdfViewer.vue"
  import { utils, writeFileXLSX } from "xlsx";

  export default {
    name: "RentalPumpInvoicing",
    components: {
      PdfViewer,
    },

    data() {
      return {
        // searchFromDate: null,
        asOfDate: null,
        pumpTypes: [],
        selectPumpType: null,
        pumpListReport: [],
        pdfComponentReady: false,
        fileUrl: "",

        pump_list_fields: [
          { key: "customer_id", label: "Customer #", sortable: true },
          { key: "customer_name", label: "Name", sortable: true },
          { key: "customer_address_1", label: "Address", sortable: true },
          { key: "customer_city", label: "City", sortable: true },
          { key: "customer_prov", label: "Prov", sortable: true },
          { key: "customer_insured", label: "Insured", sortable: true },
          { key: "customer_pump_count", label: "Count", sortable: true },
        ],
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader"]),

      disableSearch() {
        if (this.selectPumpType === "" || this.selectPumpType === null) {
          return true
        }
        return false
      },

      searchButtonLabel() {
        if (this.searchingForCustomers) {
          return "Searching..."
        }
        return "Search"
      },
    },

    created() {},

    watch: {},

    async mounted() {
      document.title = "Rental Pump Invoicing"
      // Get pump types for dropdown
      await django_session
        .get("erp/pump/type/list", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => {
          this.pumpTypes = response.data
        })
    },

    methods: {
      async getPumpReportData() {
        this.$store.dispatch("common/showLoader", true)
        await django_session
        .get("erp/pump/rental-invoicing-report/", {
          params: {
            as_of_date: this.asOfDate,
            pump_type: this.selectPumpType,
          },
        })
        .then((response) => {
          console.log("response", response)
          this.pumpListReport = response.data
        }).catch((error) => {
          console.log("error", error)
        })
        this.$store.dispatch("common/showLoader", false)
      },

      async generateXLSX() {
        // console.log("generateSheet")
        this.$store.dispatch("common/showLoader", true)
        await this.getPumpReportData()
        const workbook = utils.json_to_sheet(this.pumpListReport)
        const export_file = utils.book_new()
        utils.book_append_sheet(export_file, workbook, "Report")
        let reportDateString = ""
        if (this.asOfDate === null || this.asOfDate === "") {
          reportDateString = new Date().toDateString("%Y-%m-%d")
        }
        else {
          reportDateString = this.asOfDate
        }
        let pumpTypeName = this.pumpTypes.find((pumpType) => pumpType.pump_type_id === this.selectPumpType).name
        let filename = pumpTypeName + " " + reportDateString + " " + " Rental Pump Invoicing.xlsx"
        utils.sheet_add_aoa(workbook, [["Customer #", "Name", "Address", "City", "Prov", "Postal Code", "Insured", "Count"]], { origin: "A1" });
        this.$store.dispatch("common/showLoader", false)
        writeFileXLSX(export_file, filename)
      },

      watchPdfComponent(event) {
        console.log("pdfComponentReady", event)
        this.pdfComponentReady = event
      },

      clear() {
        this.asOfDate = null
        this.selectPumpType = null
        this.fileUrl = ""
        this.pumpListReport = []
        this.watchPdfComponent(false)
      },
    },
  }
</script>
<style scoped>
  #pdf-view {
    height: calc(100vh - 300px);
    overflow-y: auto;
  }
</style>
