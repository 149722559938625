<template>
  <div class="">
    <div v-if="!showLoader">
      <m-page-title title="Customer Weekly Sales" />
      <div class="alert alert-warning" v-if="showQueryWarning">
        <strong>Warning!</strong> Your query parameters may result in a large data set. Please be patient. If the report takes too long to generate, please refine your search criteria. We recomend a maximum of 14 days for the date range, less when 'All My Customers' is selected.
      </div>
      <form @submit.prevent="searchCustomer">
        <div class="row align-items-start">
          <div class="col-lg-12 col-md-12">
            <b-card>
              <div class="flex-row">
                <div class="flex-column">
                  <b-form-group
                    label="Select Search By Option"
                    v-slot="{ ariaDescribedby }">
                    <b-form-radio-group
                      v-model="selectedSearchBy"
                      :options="searchByOptions"
                      :aria-describedby="ariaDescribedby"
                      name="radio-inline"></b-form-radio-group>
                  </b-form-group>
                </div>
                <div class="flex-column flex-shrink-1">
                  <b-input-group
                    v-if="selectedSearchBy === 'customer'"
                    class="mt-3">
                    <b-form-input
                      v-model="searchCustomerName"
                      class="h-auto"></b-form-input>
                    <b-input-group-append>
                      <b-button
                        variant="success"
                        @click="searchCustomer()"
                        >Search</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <b-form-group
                    v-if="selectedSearchBy === 'territory'"
                    id="select-territory"
                    label="Select Territory"
                    label-for="territory-select"
                    class="field-label-form-group mt-2 mb-0">
                    <b-form-select
                      v-model="territoryId"
                      id="territory-select"
                      :options="filteredTerritoryList"
                      value-field="territory_id"
                      text-field="territory_name" />
                  </b-form-group>
                </div>
              </div>
              <div class="row align-items-end">
                <div class="col-lg-6 col-md-12">
                  <m-text-input
                    id="from-date"
                    label="From"
                    type="date"
                    :value="searchFromDate"
                    :alternative="false"
                    :group="false"
                    name="From Date"
                    @update-value="
                      (input) => {
                        searchFromDate = input
                      }
                    " />
                </div>
                <div class="col-lg-6 col-md-12">
                  <m-text-input
                    id="to-date"
                    label="To"
                    type="date"
                    :value="searchToDate"
                    :alternative="false"
                    :group="false"
                    name="To Date"
                    @update-value="
                      (input) => {
                        searchToDate = input
                      }
                    " />
                </div>
              </div>
              <div class="row align-items-start justify-content-between mt-4">
                <div class="col">
                  <b-button
                    size="md"
                    variant="success"
                    :disabled="disableSearch"
                    @click="generateReport()">
                    {{ searchButtonLabel }}
                  </b-button>
                </div>
                <div class="col float-right">
                  <b-button
                    size="md"
                    variant="primary"
                    @click="clear()">
                    Clear
                  </b-button>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </form>

      <b-alert
        v-model="showCustomerIdError"
        variant="danger"
        dismissible>
        We had an issue retrieving the customer information. Please try again.
      </b-alert>
      <div v-if="noSearchResults">
        <b-alert
          title="No Results"
          variant="warning"
          show>
          No results found for the selected search criteria.
        </b-alert>
      </div>
      <!--  -->
      <!-- Customer Search Results Modal -->
      <!--  -->
      <b-modal
        id="customer-search-modal"
        v-model="showCustomerSearchResultsModal"
        title="Customer Search Results"
        size="xl"
        centered
        scrollable
        header-bg-variant="default"
        header-text-variant="white"
        no-close-on-esc
        no-close-on-backdrop
        hide-header-close>
        <b-table
          id="customer-search-results"
          responsive
          head-variant="dark"
          :items="searchCustomerResults"
          :fields="searchResultsFields"
          per-page="0">
          <template #cell(action)="row">
            <i
              class="fas fa-circle-check text-primary icon-pointer"
              @click="selectCustomer(row.item)" />
          </template>
        </b-table>

        <template #modal-footer="{}">
          <div>
            <b-button
              size="md"
              class="mr-2"
              variant="info"
              @click="showCustomerSearchResultsModal = false">
              Cancel
            </b-button>
          </div>
        </template>
      </b-modal>
      <div class="row my-4">
        <div class="col">
          <div id="pdf-viewer">
            <pdf-viewer
              :file-url="fileUrl"
              file-name="Customer Sales History.pdf"
              @pdf-ready="watchPdfComponent($event)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  import PdfViewer from "@/components/PdfViewer.vue"
  const baseDomain = process.env.VUE_APP_BASE_DOMAIN

  export default {
    name: "CustomerSalesHistory",
    components: {
      PdfViewer,
    },

    data() {
      return {
        searchFromDate: null,
        searchToDate: null,
        customerId: null,
        searchingForCustomers: false,
        searchCustomerName: "",
        searchCustomerResults: [],
        searchResultsFields: [
          {
            key: "action",
            label: "",
          },
          {
            key: "customer_name",
            label: "Name",
          },
        ],

        selectedSearchBy: "all",
        searchByOptions: [
          { text: "Search by Customer", value: "customer" },
          { text: "Search by Territory", value: "territory" },
          { text: "All My Customers", value: "all" },
        ],

        territoryList: [],
        territoryId: null,
        pdfComponentReady: false,
        fileUrl: "",
        showCustomerIdError: false,
        showCustomerSearchResultsModal: false,

        noSearchResults: false,
        showSearchResults: false,
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader"]),

      disableSearch() {
        if (this.selectedSearchBy === "customer" && this.customerId === null) {
          return true
        }
        if (this.selectedSearchBy === "territory" && this.territoryId === null) {
          return true
        }
        return false
      },

      searchButtonLabel() {
        if (this.searchingForCustomers) {
          return "Searching..."
        }
        return "View"
      },

      userIsSalesRep() {
        if (this.roles.includes("SLS_REP") && !this.roles.includes("SLS_MGR") && !this.roles.includes("ADMIN")) {
          return true
        }
        return false
      },

      showQueryWarning() {
        let date1, date2, diffTime, diffDays
        if (this.searchFromDate === null) {
          date1 = new Date()
        } else {
          date1 = new Date(this.searchFromDate)
        }

        if (this.searchToDate === null) {
          date2 = new Date()
        } else {
          date2 = new Date(this.searchToDate)
        }

        diffTime = Math.abs(date2 - date1)
        diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

        console.log("showQueryWarning", diffDays)
        if (this.selectedSearchBy === "all" && diffDays > 10) {
          return true
        }
        if (diffDays > 14) {
          return true
        }
        return false
      },

      filteredTerritoryList() {
        let list = this.territoryList
        if (this.userIsSalesRep) {
          return list.filter((territory) => territory.sales_rep_id === this.user.user_id)
        }
        return list
      },
    },

    created() {},

    watch: {
      selectedSearchBy(newVal, oldVal) {
        if (oldVal !== newVal) {
          // console.log("selectedSearchByNew", newVal, oldVal)
          this.searchCustomerName = ""
          this.customerId = null
          this.territoryId = null
          this.salesRepId = null
          if (this.searchCustomerResults.length > 0) {
            this.searchCustomerResults = []
            document.getElementById("customer-options").innerHTML = ""
          }
        }
      },
    },

    async mounted() {
      document.title = "Customer Sales Reporting"
      this.$store.dispatch("common/showLoader", true)
      await django_session
        .get("erp/customer/territory/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => {
          this.territoryList = response.data
        })

      // await django_session
      //   .get("erp/user/list-sales-rep/", {
      //     params: {
      //       page_size: 0,
      //     },
      //   })
      //   .then((response) => {
      //     this.salesRepList = response.data
      //   })

      // if (this.userIsSalesRep) {
      //   this.salesRepId = this.user.user_id
      //   this.selectedSearchBy = "salesrep"
      //   this.disableSalesRepSelect = true
      // }

      // await django_session
      //   .get("erp/customer/category/list/", {
      //     params: {
      //       page_size: 0,
      //     },
      //   })
      //   .then((response) => {
      //     this.categoryList = response.data
      //   })
      this.$store.dispatch("common/showLoader", false)
    },

    methods: {
      async searchCustomer() {
        this.searchingForCustomers = true
        await django_session
          .get("erp/customer/search/", {
            params: {
              keyword: this.searchCustomerName,
              page_size: 0,
            },
          })
          .then((response) => {
            this.searchCustomerResults = response.data
            for (var a = 0; a < this.searchCustomerResults.length; a++) {
              this.searchCustomerResults[a].customer_name = this.searchCustomerResults[a].customer_name.trim()
            }
          })
        this.searchingForCustomers = false
        this.showCustomerSearchResultsModal = true
      },

      selectCustomer(item) {
        // console.log("select customer", item)
        this.customerId = item.erp_customer_id
        this.showCustomerSearchResultsModal = false
        this.searchCustomerName = item.customer_name
      },

      resetFindCustomer() {
        this.customerId = null
        this.showCustomerIdError = false
        this.searchCustomerResults = []
      },

      // getAllCustomerSalesYesterday() {
      //   let today = new Date()
      //   let yesterday = new Date(today)
      //   yesterday.setDate(yesterday.getDate() - 1)
      //   this.searchFromDate = yesterday.toISOString().slice(0, 10)
      //   this.searchToDate = yesterday.toISOString().slice(0, 10)
      //   this.periodType = "Daily"
      //   this.showInactiveCustomersYN = false
      //   this.showSalesDetailsYN = true
      //   this.viewResults()
      // },

      // getAllCustomersSalesLast5Days() {
      //   let today = new Date()
      //   let fiveDaysAgo = new Date(today)
      //   fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 5)
      //   this.searchFromDate = fiveDaysAgo.toISOString().slice(0, 10)
      //   this.searchToDate = today.toISOString().slice(0, 10)
      //   this.periodType = "Daily"
      //   this.showInactiveCustomersYN = false
      //   this.showSalesDetailsYN = true
      //   this.viewResults()
      // },

      // async generateReport() {
      //   this.$store.dispatch("common/showLoader", true)
      //   console.log("search")
      //   this.fileUrl =
      //     baseDomain +
      //     "/erp/customer/salesHistoryReport?fromDate=" +
      //     this.searchFromDate +
      //     "&toDate=" +
      //     this.searchToDate +
      //     "&customer=" +
      //     this.customerId +
      //     "&periodType=" +
      //     this.periodType
      //   console.log("fileUrl", this.fileUrl)
      //   this.$store.dispatch("common/showLoader", false)
      // },

      async generateReport() {
        this.fileUrl =
          baseDomain +
          "erp/customer/sales-history/?fromDate=" +
          this.searchFromDate +
          "&toDate=" +
          this.searchToDate +
          "&customer=" +
          this.customerId +
          "&territory=" +
          this.territoryId +
          "&queryType=" +
          this.selectedSearchBy
      },

      // async viewResults() {
      //   this.$store.dispatch("common/showLoader", true)
      //   this.noSearchResults = false
      //   this.showCustomerIdError = false
      //   await django_session
      //     .get("erp/customer/sales-history/", {
      //       params: {
      //         queryType: this.selectedSearchBy,
      //         fromDate: this.searchFromDate,
      //         toDate: this.searchToDate,
      //         customer: this.customerId,
      //         territory: this.territoryId,
      //         page_size: 0,
      //       },
      //     })
      //     .then((response) => {
      //       this.salesHistoryData = response.data
      //       if (this.salesHistoryData.length === 0) {
      //         this.noSearchResults = true
      //       }
      //     })
      //     .catch((error) => {
      //       console.log("error", error)
      //     })
      //   this.$store.dispatch("common/showLoader", false)
      // },

      // async generateXLSX() {
      //   // console.log("generateSheet")
      //   this.$store.dispatch("common/showLoader", true)
      //   await this.viewResults()
      //   const workbook = utils.json_to_sheet(this.salesHistoryData)
      //   const export_file = utils.book_new()
      //   utils.book_append_sheet(export_file, workbook, "Report")
      //   let filename = "Sales History Report.xlsx"
      //   utils.sheet_add_aoa(workbook, [["Customer #", "Name", "Address", "City", "Prov", "Insured", "Count"]], {
      //     origin: "A1",
      //   })
      //   this.$store.dispatch("common/showLoader", false)
      //   writeFileXLSX(export_file, filename)
      // },

      watchPdfComponent(event) {
        console.log("pdfComponentReady", event)
        this.pdfComponentReady = event
      },

      clear() {
        this.searchFromDate = null
        this.searchToDate = null
        this.fileUrl = ""
        this.searchCustomerName = ""
        this.customerId = null
        ;(this.salesHistoryData = [
          {
            period_data: [],
          },
        ]),
          (this.showCustomerIdError = false)
        this.showCustomerSearchResultsModal = false
        this.noSearchResults = false
        this.customerCategories = []
        this.selectedCategories = []
        this.territoryId = null
        this.showInactiveCustomersYN = false
        this.showSalesDetailsYN = false
        this.searchCustomerResults = []
        this.selectedSearchBy = "customer"
        // if (this.userIsSalesRep) {
        //   this.salesRepId = this.user.user_id
        //   this.disableSalesRepSelect = true
        //   this.selectedSearchBy = "salesrep"
        // }
      },
    },
  }
</script>
<style lang="scss" scoped>
  table {
    /* border-collapse: collapse; */
    width: 100%;
    font-size: 0.9rem;
    font-weight: 300;
  }

  td {
    vertical-align: top;
  }

  .table-container {
    width: 100%;
    overflow-x: auto;
    max-height: calc(100vh - 300px);
    min-height: 300px;
  }

  .bordered {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }

  .stretch-table {
    // width: 100%;
    overflow-x: scroll;
  }

  .first-column {
    min-width: 500px;
  }

  .data-columns {
    min-width: 100px;
  }

  /deep/ #customer-search-modal {
    display: block;
    width: 30%;
    height: 100%;
    left: 70%;
  }

  /deep/ .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    height: 100%;
  }
</style>
