<template>
  <div class="">
    <div v-if="!showLoader">
      <m-page-title title="Service Request History" />
      <div class="my-2 align-items-end row">
        <div class="flex-column flex-shrink-1 mr-3">
          <m-text-input
            id="from-date"
            label="From"
            type="date"
            :value="searchFromDate"
            :alternative="false"
            :group="false"
            name="From Date"
            @update-value="
              (input) => {
                searchFromDate = input
              }
            " />
        </div>
        <div class="flex-column flex-shrink-1 mr-3">
          <m-text-input
            id="to-date"
            label="To"
            type="date"
            :value="searchToDate"
            :alternative="false"
            :group="false"
            name="To Date"
            @update-value="
              (input) => {
                searchToDate = input
              }
            " />
        </div>
        <div class="flex-column flex-shrink-1">
          <b-form-group
            id="sr-type-list"
            label="Reason Code"
            label-for="sr-reason-code"
            class="field-label-form-group mt-2 mb-0">
            <b-form-select
              id="sr-reason-code"
              v-model="searchReasonCode"
              :options="serviceRequestTypeCodes"
              value-field="entity_type_code_id"
              text-field="type_label"></b-form-select>
          </b-form-group>
        </div>
        <div class="flex-column ml-4">
          <b-button
            size="md"
            class="mr-2"
            variant="info"
            @click="search()">
            Search
          </b-button>
          <b-button
            size="md"
            class="mr-2"
            variant="primary"
            @click="clear()">
            Clear
          </b-button>
        </div>
      </div>
        <pdf-viewer :file-url="fileUrl" file-name="Service Request History.pdf" @pdf-ready="watchPdfComponent($event)" />
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex"
  import { baseDomain } from "@/constants/common"
  import PdfViewer from "@/components/PdfViewer.vue"

  export default {
    name: "ServiceRequestHistory",
    components: {
      PdfViewer,
    },

    data() {
      return {
        searchFromDate: null,
        searchToDate: null,
        searchReasonCode: null,
        serviceRequestList: [],
        pdfComponentReady: false,
        fileUrl: "",
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader", "serviceRequestTypeCodes"]),
    },

    created() {},

    watch: {},

    async mounted() {
      document.title = "Service Request History"
    },

    methods: {
      async search() {
        this.$store.dispatch("common/showLoader", true)
        this.fileUrl = baseDomain + "erp/service/serviceRequestHistoryReport?fromDate=" + this.searchFromDate + "&toDate=" + this.searchToDate + "&reasonCode=" + this.searchReasonCode
        console.log("fileUrl", this.fileUrl)
        this.$store.dispatch("common/showLoader", false)
      },

      watchPdfComponent(event) {
        console.log("pdfComponentReady", event)
        this.pdfComponentReady = event
      },

      clear() {
        this.searchFromDate = null
        this.searchToDate = null
        this.searchReasonCode = null
        this.fileUrl = ""
        this.pdfComponentReady = false
      },
    },
  }
</script>
<style scoped>
</style>
