<template>
  <div>
    <div v-if="!showLoader">
      <m-page-title title="Depot Inventory Report" />
      <div class="my-2 align-items-end row">
        <div class="flex-column flex-shrink-1 mr-2">
          <b-form-group
            id="depot-list"
            label="Depot"
            label-for="depot-select"
            class="field-label-form-group mt-2 mb-0">
            <b-form-select
              id="depot-select"
              v-model="selectDepot"
              :options="depotList"
              value-field="depot_id"
              text-field="name"></b-form-select>
          </b-form-group>
        </div>
        <div class="flex-column flex-shrink-1 mr-2">
          <b-form-group
            id="pump-type-list"
            label="Pump Type"
            label-for="pump-type-select"
            class="field-label-form-group mt-2 mb-0">
            <b-form-select
              id="pump-type-select"
              v-model="selectPumpType"
              :options="pumpTypeList"
              value-field="pump_type_id"
              text-field="name"></b-form-select>
          </b-form-group>
        </div>
        <div class="flex-column ml-4">
          <b-button
            size="md"
            class="mr-2"
            variant="info"
            :disabled="disableSearch"
            @click="generateXLSX()">
            Generate Report (XLSX)
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { utils, writeFileXLSX } from "xlsx";
import django_session from "@/api/django_session"

export default {
  name: "DepotInventory",
  components: {},

  data() {
    return {
      depotList: [],
      pumpTypeList: [],
      selectDepot: null,
      selectPumpType: null,
      depotPumpList: [],
    }
  },

  computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader"]),

      disableSearch() {
        return !this.selectDepot
      },
  },

  created() {

  },

  async mounted() {
    document.title = "Depot Inventory Report"
      this.$store.dispatch("common/showLoader", true)
      await django_session
        .get("erp/pump/type/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => {
          this.pumpTypeList = response.data
          this.pumpTypeList.unshift({ pump_type_id: null, name: "All" })
        })
        .catch((error) => {
          console.log(error)
        })
      await django_session.get("erp/pump/depot/list/", {
        params: {
          page_size: 0,
        },
        }).then((response) => {
          this.depotList = response.data
        }).catch((error) => {
          console.log(error)
        })

      this.$store.dispatch("common/showLoader", false)
  },

  methods: {
    generateXLSX() {
      this.$store.dispatch("common/showLoader", true)
      django_session
        .get("erp/pump/pump-depot-list-report/", {
          params: {
            page_size: 0,
            depotId: this.selectDepot,
            pumpType: this.selectPumpType,
          },
        })
        .then((response) => {
          this.depotPumpList = response.data
          // Parse the data to remove unwanted fields
          this.depotPumpListExport = this.depotPumpList.map((pump) => {
            return {
              "Serial Number": pump.serial_no,
              "Pump Type": pump.pump_type_name,
              "Last Transfer Date": pump.last_transfer_date,
              "Last Pump Note": pump.last_pump_note,
            }
          })

          let columnNames = [
            "Serial Number",
            "Pump Type",
            "Last Transfer Date",
            "Last Pump Note",
          ]

          let depotName = this.depotList.find(
            (depot) => depot.depot_id === this.selectDepot
          ).name

          let fileName = "Depot Inventory Report for " + depotName + " as of " + new Date().toLocaleDateString() + ".xlsx"
          const workbook = utils.json_to_sheet(this.depotPumpListExport)
          const export_file = utils.book_new()
          utils.book_append_sheet(export_file, workbook, depotName)
          utils.sheet_add_aoa(workbook, [columnNames], { origin: "A1" })
          writeFileXLSX(export_file, fileName)
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.$store.dispatch("common/showLoader", false)
        })
    }
  }
}
</script>