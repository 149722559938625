<template>
  <div class="">
    <div v-if="!showLoader">
      <m-page-title title="Supplier Sales" />
      <!-- <div class="alert alert-warning" v-if="showQueryWarning">
        <strong>Warning!</strong> Your query parameters may result in a large data set. Please be patient. If the report takes too long to generate, please refine your search criteria. We recomend a maximum of 14 days for the date range, less when 'All My Customers' is selected.
      </div> -->
      <form @submit.prevent="searchCustomer">
        <div class="row align-items-start">
          <div class="col-lg-12 col-md-12">
            <b-card>
              <div class="flex-row">
                <b-form-group
                  id="select-supplier"
                  label="Select Supplier"
                  label-for="supplier-select"
                  class="field-label-form-group mt-2 mb-0">
                  <b-form-select
                    v-model="supplierId"
                    id="territory-select"
                    :options="supplierList"
                    value-field="erp_supplier_id"
                    :required="true"
                    text-field="name" />
                </b-form-group>
              </div>
              <div class="row align-items-end">
                <div class="col-lg-3 col-md-12">
                  <m-text-input
                    id="from-date"
                    label="Current From"
                    type="date"
                    :value="searchCurrentFromDate"
                    :alternative="false"
                    :group="false"
                    :required="true"
                    name="From Date"
                    @update-value="
                      (input) => {
                        searchCurrentFromDate = input
                      }
                    " />
                </div>
                <div class="col-lg-3 col-md-12">
                  <m-text-input
                    id="to-date"
                    label="Current To"
                    type="date"
                    :value="searchCurrentToDate"
                    :alternative="false"
                    :group="false"
                    :required="true"
                    name="To Date"
                    @update-value="
                      (input) => {
                        searchCurrentToDate = input
                      }
                    " />
                </div>
                <div class="col-lg-3 col-md-12">
                  <m-text-input
                    id="previous-from-date"
                    label="Compare From"
                    type="date"
                    :value="searchCompareFromDate"
                    :alternative="false"
                    :group="false"
                    :required="true"
                    name="From Date"
                    @update-value="
                      (input) => {
                        searchCompareFromDate = input
                      }
                    " />
                </div>
                <div class="col-lg-3 col-md-12">
                  <m-text-input
                    id="previous-to-date"
                    label="Compare To"
                    type="date"
                    :value="searchCompareToDate"
                    :alternative="false"
                    :group="false"
                    :required="true"
                    name="To Date"
                    @update-value="
                      (input) => {
                        searchCompareToDate = input
                      }
                    " />
                </div>
              </div>
              <div class="row align-items-start justify-content-between mt-4">
                <div class="col">
                  <b-button
                    size="md"
                    variant="success"
                    :disabled="disableSearch"
                    @click="generateReport()">
                    {{ searchButtonLabel }}
                  </b-button>
                </div>
                <div class="col float-right">
                  <b-button
                    size="md"
                    variant="primary"
                    @click="clear()">
                    Clear
                  </b-button>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </form>
      <div v-if="noSearchResults">
        <b-alert
          title="No Results"
          variant="warning"
          show>
          No results found for the selected search criteria.
        </b-alert>
      </div>
      <div class="row my-4">
        <div class="col">
          <div id="pdf-viewer">
            <pdf-viewer
              :file-url="fileUrl"
              file-name="Supplier Sales History.pdf"
              @pdf-ready="watchPdfComponent($event)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex"
  import django_session from "@/api/django_session"
  import PdfViewer from "@/components/PdfViewer.vue"
  const baseDomain = process.env.VUE_APP_BASE_DOMAIN

  export default {
    name: "SupplierSalesHistory",
    components: {
      PdfViewer,
    },

    data() {
      return {
        searchCurrentFromDate: "2024-01-01",
        searchCurrentToDate: "2024-01-10",
        searchCompareFromDate: "2023-01-01",
        searchCompareToDate: "2023-01-10",
        supplierList: [],
        supplierId: 7,
        pdfComponentReady: false,
        fileUrl: "",
        showCustomerIdError: false,
        showCustomerSearchResultsModal: false,

        noSearchResults: false,
        showSearchResults: false,
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader"]),

      disableSearch() {
        if (this.supplierId === null || this.searchCurrentFromDate === null ||  this.searchCurrentToDate === null || this.searchCompareFromDate === null || this.searchCompareToDate === null) {
          return true
        }
        return false
      },

      searchButtonLabel() {
        if (this.searchingForCustomers) {
          return "Searching..."
        }
        return "View"
      },

      userIsSalesRep() {
        if (this.roles.includes("SLS_REP") && !this.roles.includes("SLS_MGR") && !this.roles.includes("ADMIN")) {
          return true
        }
        return false
      },
    },

    created() {},

    watch: {
      // selectedSearchBy(newVal, oldVal) {
      //   if (oldVal !== newVal) {
      //     // console.log("selectedSearchByNew", newVal, oldVal)
      //     this.searchCustomerName = ""
      //     this.customerId = null
      //     this.territoryId = null
      //     this.salesRepId = null
      //     if (this.searchCustomerResults.length > 0) {
      //       this.searchCustomerResults = []
      //       document.getElementById("customer-options").innerHTML = ""
      //     }
      //   }
      // },
    },

    async mounted() {
      document.title = "Supplier Sales Report"
      this.$store.dispatch("common/showLoader", true)
      await django_session
        .get("erp/product/supplier/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => {
          this.supplierList = response.data.filter((supplier) => supplier.is_inactive_yn === false)
        })
      this.$store.dispatch("common/showLoader", false)
    },

    methods: {
      async generateReport() {
        this.fileUrl =
          baseDomain +
          "erp/product/supplier-sales-history/?currentFromDate=" +
          this.searchCurrentFromDate +
          "&currentToDate=" +
          this.searchCurrentToDate +
          "&compareFromDate=" +
          this.searchCompareFromDate +
          "&compareToDate=" +
          this.searchCompareToDate +
          "&supplier=" +
          this.supplierId
      },

      watchPdfComponent(event) {
        console.log("pdfComponentReady", event)
        this.pdfComponentReady = event
      },

      clear() {
        // this.searchCurrentFromDate = null
        // this.searchCurrentToDate = null
        // this.searchCompareFromDate = null
        // this.searchCompareToDate = null
        this.fileUrl = ""
        // this.supplierId = null
        this.noSearchResults = false
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>
