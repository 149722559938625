<template>
  <div v-if="!showLoader">
    <m-page-title title="Sales Performance" />
    <div class="row my-2 align-items-center">
      <b-form-group
        label="Select One:"
        v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          id="query-type"
          v-model="reportQueryType"
          :aria-describedby="ariaDescribedby"
          name="radio-sub-component">
          <b-form-radio
            v-if="!userIsSalesRep"
            value="supplierQuery"
            >Product Line (Supplier)</b-form-radio
          >
          <b-form-radio value="salesRepQuery">Sales Rep</b-form-radio>
        </b-form-radio-group>
      </b-form-group>
    </div>
    <div class="row my-4 align-items-center">
      <div class="flex-column flex-grow-1">
        <b-form-group
          id="select-fiscal-year"
          label="Fiscal Year"
          label-for="fiscal-year"
          class="field-label-form-group mt-2 mx-2">
          <b-form-select
            id="fiscal-year"
            v-model="searchFiscalYear"
            :options="fiscalYear">
          </b-form-select>
        </b-form-group>
      </div>
      <div
        v-if="supplierQuery"
        class="flex-column flex-grow-1">
        <b-form-group
          id="select-supplier"
          label="Supplier"
          label-for="supplier"
          class="field-label-form-group mt-2 mx-2">
          <b-form-select
            id="supplier"
            v-model="searchSupplier"
            :options="supplierList"
            value-field="erp_supplier_id"
            text-field="name">
          </b-form-select>
        </b-form-group>
      </div>
      <span
        v-if="supplierQuery"
        class="i fas fa-circle-xmark text-danger ml-1 icon-pointer"
        @click="
          () => {
            searchSupplier = null
          }
        "></span>
      <div
        v-if="salesRepQuery"
        class="flex-column flex-grow-1">
        <b-form-group
          id="select-sales-rep"
          label="Sales Rep"
          label-for="sales-rep"
          class="field-label-form-group mt-2 mx-2">
          <b-form-select
            id="sales-rep"
            v-model="searchSalesRep"
            :options="salesRepList"
            :disabled="userIsSalesRep"
            value-field="user_id"
            text-field="full_name">
          </b-form-select>
        </b-form-group>
      </div>
      <span
        v-if="salesRepQuery"
        class="i fas fa-circle-xmark text-danger ml-1 icon-pointer"
        @click="
          () => {
            searchSalesRep = null
          }
        "></span>
      <div class="flex-column flex-shrink-1">
        <b-button
          variant="primary"
          class="mx-2"
          @click="runReport"
          >View Report</b-button
        >
        <b-button
          variant="info"
          class="mx-2"
          :disabled="disableGeneratePdfButton"
          @click="generatePDF"
          >Generate PDF</b-button
        >
        <!-- <b-button variant="warning" class="mx-2" disabled @click="runReport">Generate Excel (not yet)</b-button> -->
        <b-button
          variant="secondary"
          class="mx-2"
          @click="clearReport"
          >Clear</b-button
        >
      </div>
    </div>
    <div v-if="showReportData">
      <b-card>
        <div class="row my-4">
          <b-colxx
            md="12"
            sm="12">
            <h4 fw-bold>
              Target Revenue and Actual Sales for: {{ reportData.current_fiscal_start }} to
              {{ reportData.current_fiscal_end }}
            </h4>
          </b-colxx>
          <b-colxx
            md="12"
            sm="12">
            <h4>
              Based on Revenue from: {{ reportData.previous_fiscal_start }} to {{ reportData.previous_fiscal_end }}
            </h4>
          </b-colxx>
        </div>
      </b-card>

      <div
        v-if="supplierQuery"
        class="row my-4">
        <b-table
          id="sales-report-table"
          ref="__salesReportTable"
          responsive
          head-variant="dark"
          :items="reportData.detail"
          :fields="report_fields">
          <template #cell(sales_target_expected_growth_percent)="row">
            {{ row.item.sales_target_expected_growth_percent }} %
          </template>
          <template #cell(sales_target_total_dollars)="row"> $ {{ row.item.sales_target_total_dollars }} </template>
          <template #cell(current_year_sales_actual)="row">
            $ {{ row.item.current_year_sales_actual.toFixed(2) }}
          </template>
          <template #cell(variance_dollars)="row">
            <span
              v-if="row.item.variance_dollars < 0"
              class="text-danger"
              ><strong>$ {{ row.item.variance_dollars.toFixed(2) }}</strong></span
            >
            <span
              v-else
              class="text-success"
              ><strong>$ {{ row.item.variance_dollars.toFixed(2) }}</strong></span
            >
          </template>
          <template #cell(variance_percent)="row">
            <span
              ><strong>{{ row.item.sales_to_target_percent.toFixed(2) }} %</strong></span
            >
          </template>
          <template #custom-foot>
            <td class="strong-footer">Totals</td>
            <td class="text-right strong-footer">
              {{ parseFloat(reportData.overall_sales_target_expected_growth_percent).toFixed(2) }} %
            </td>
            <td class="text-right strong-footer">$ {{ reportData.overall_sales_target_current_year_target_sales }}</td>
            <td class="text-right strong-footer">$ {{ reportData.overall_current_year_sales_actual }}</td>
            <td class="text-right strong-footer">$ {{ reportData.overall_sales_target_variance_dollars }}</td>
            <td class="text-right strong-footer">{{ reportData.overall_sales_target_sales_to_target }} %</td>
          </template>
        </b-table>
      </div>
      <div v-if="salesRepQuery">
        <div
          v-for="(rep, index) in reportData.detail"
          :key="index"
          class="card my-4 p-4">
          <h2>{{ rep.sales_rep_name }}</h2>
          <b-table
            id="sales-report-table"
            ref="__salesReportTable"
            responsive
            head-variant="dark"
            :items="rep.supplier_data"
            :fields="report_fields">
            <template #cell(sales_target_expected_growth_percent)="row">
              {{ row.item.sales_target_expected_growth_percent.toFixed(2) }} %
            </template>
            <template #cell(sales_target_total_dollars)="row">
              $ {{ row.item.sales_target_total_dollars.toFixed(2) }}
            </template>
            <template #cell(current_year_sales_actual)="row">
              $ {{ row.item.current_year_sales_actual.toFixed(2) }}
            </template>
            <template #cell(variance_dollars)="row">
              <span
                v-if="row.item.variance_dollars < 0"
                class="text-danger"
                ><strong>$ {{ row.item.variance_dollars.toFixed(2) }}</strong></span
              >
              <span
                v-else
                class="text-success"
                ><strong>$ {{ row.item.variance_dollars.toFixed(2) }}</strong></span
              >
            </template>
            <template #cell(variance_percent)="row">
              <span
                ><strong>{{ row.item.sales_to_target_percent.toFixed(2) }}%</strong></span
              >
            </template>
            <template #custom-foot>
              <td class="strong-footer">Totals</td>
              <td class="text-right strong-footer"></td>
              <!-- <td class="text-right strong-footer"> {{  parseFloat(rep.total_sales_rep_expected_growth_percent).toFixed(2) }} %</td> -->
              <td class="text-right strong-footer">$ {{ parseFloat(rep.total_sales_rep_target).toFixed(2) }}</td>
              <td class="text-right strong-footer">$ {{ parseFloat(rep.total_sales_rep_sales).toFixed(2) }}</td>
              <td class="text-right strong-footer">
                $ {{ parseFloat(rep.total_sales_rep_variance_dollars).toFixed(2) }}
              </td>
              <td class="text-right strong-footer">
                {{ parseFloat(rep.total_sales_rep_sales_to_target_percent).toFixed(2) }} %
              </td>
            </template>
          </b-table>
        </div>
      </div>
    </div>
    <pdf-viewer
      :file-url="fileUrl"
      file-name="Sales Performance.pdf"
      @pdf-ready="watchPdfComponent($event)" />
  </div>
</template>

<script>
  import django_session from "@/api/django_session"
  import { mapGetters } from "vuex"
  import { fiscalYear } from "@/constants/fiscal_year"
  import { baseDomain } from "@/constants/common"
  import PdfViewer from "@/components/PdfViewer.vue"

  export default {
    name: "SalesPerformanceReport",
    components: {
      PdfViewer,
    },

    data() {
      return {
        fiscalYear,
        searchFiscalYear: 2023,
        searchSupplier: null,
        searchSalesRep: null,
        supplierQuery: false,
        salesRepQuery: true,
        reportQueryType: "salesRepQuery",

        supplierList: [],
        salesRepList: [],

        reportData: [],

        report_fields: [
          {
            key: "supplier_name",
            label: "Supplier Name",
          },
          {
            key: "sales_target_expected_growth_percent",
            label: "Expected Growth",
            tdClass: "text-right",
            thClass: "text-right",
          },
          {
            key: "sales_target_total_dollars",
            label: "Target Revenue",
            tdClass: "text-right",
            thClass: "text-right",
          },
          {
            key: "current_year_sales_actual",
            label: "Actual Revenue",
            tdClass: "text-right",
            thClass: "text-right",
          },
          {
            key: "variance_dollars",
            label: "Variance $",
            tdClass: "text-right",
            thClass: "text-right",
          },
          {
            key: "sales_to_target_percent",
            label: "% of Target",
            tdClass: "text-right",
            thClass: "text-right",
          },
        ],

        showReportData: false,
        fileUrl: "",
        pdfComponentReady: false,
      }
    },

    watch: {
      reportQueryType(newVal, oldVal) {
        this.showReportData = false
        if (newVal == "supplierQuery") {
          this.supplierQuery = true
          this.salesRepQuery = false
        } else {
          this.supplierQuery = false
          this.salesRepQuery = true
        }
        if (this.userIsSalesRep) {
          this.searchSalesRep = this.user.user_id
        }
      },
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader"]),

      disableGeneratePdfButton() {
        return false
      },

      userIsSalesRep() {
        if (this.roles.includes("SLS_REP")) {
          return true
        } else {
          return false
        }
      },
    },

    created() {},

    async mounted() {
      document.title = "Sales Growth Report"
      this.$store.dispatch("common/showLoader", true)
      await django_session
        .get("erp/product/supplier/list/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => (this.supplierList = response.data))
        .catch((error) => {
          console.log(error.response)
        })
      await django_session
        .get("erp/user/list-sales-rep/", {
          params: {
            page_size: 0,
          },
        })
        .then((response) => {
          this.salesRepList = response.data
        })
      if (this.userIsSalesRep) {
        this.searchSalesRep = this.user.user_id
      }
      this.$store.dispatch("common/showLoader", false)
    },

    methods: {
      async runReport() {
        this.$store.dispatch("common/showLoader", true)
        if (this.reportQueryType == "supplierQuery") {
          this.supplierQuery = true
          this.salesRepQuery = false
        } else {
          this.supplierQuery = false
          this.salesRepQuery = true
        }
        this.showReportData = false
        await django_session
          .get("erp/finance/growth-report/", {
            params: {
              fiscal_year: this.searchFiscalYear,
              supplier: this.searchSupplier,
              sales_rep: this.searchSalesRep,
              supplier_query: this.supplierQuery,
              sales_rep_query: this.salesRepQuery,
            },
          })
          .then((response) => {
            this.reportData = response.data
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => {
            this.showReportData = true
            this.$store.dispatch("common/showLoader", false)
          })
      },

      clearReport() {
        this.searchFiscalYear = 2023
        this.searchSupplier = null
        this.searchSalesRep = null
        this.reportQueryType = "salesRepQuery"
        this.supplierQuery = false
        this.salesRepQuery = true
        this.showReportData = false
        this.reportData = []
        this.fileUrl = ""
        if (this.userIsSalesRep) {
          this.searchSalesRep = this.user.user_id
        }
        // this.pdfComponentReady = false
      },

      async generatePDF() {
        // this.$store.dispatch("common/showLoader", true)
        this.fileUrl =
          baseDomain +
          "erp/finance/salesPerformanceReport?fiscalYear=" +
          this.searchFiscalYear +
          "&supplier=" +
          this.searchSupplier +
          "&salesRep=" +
          this.searchSalesRep +
          "&supplierQuery=" +
          this.supplierQuery +
          "&salesRepQuery=" +
          this.salesRepQuery +
          "&token=" +
          this.user.gem_token
        // await django_session.get(this.fileUrl)
        console.log("fileUrl", this.fileUrl)
        // window.open(this.fileUrl, "_blank")
        // this.$store.dispatch("common/showLoader", false)
        this.showReportData = false
      },

      watchPdfComponent(event) {
        console.log("pdfComponentReady", event)
        this.pdfComponentReady = event
      },
    },
  }
</script>

<style lang="scss" scoped>
  .strong-footer {
    font-weight: bold;
    background-color: #009ac4;
    color: white;
    border: 1px solid white;
    font-size: 1rem;
  }
</style>
