<template>
  <div>
    <div id="pdf-view"></div>
  </div>
</template>

<script>
  import django_session from "@/api/django_session"
  const pdfPreviewConfig = {
    embedMode: "FULL_WINDOW",
    showDownloadPDF: true,
    showZoomControl: true,
    defaultViewMode: "FIT_WIDTH",
    showPrintPDF: true,
  }

  export default {
    name: "",
    components: {},

    props: {
      fileUrl: {
        type: String,
        required: true,
      },
      fileName: {
        type: String,
        required: false,
        default: "ReportName.pdf",
      },
    },

    data() {
      return {
        pdfAPIReady: false,
        adobeDCView: null,
      }
    },

    watch: {
      async fileUrl(newVal, oldVal) {
        console.log("Prop changed: ", newVal, " | was: ", oldVal)
        if (newVal !== oldVal && newVal !== "") {
          await this.loadDocument()
          this.pdfAPIReady = true
        }
        if (!newVal) {
          console.log("PDFViewer.vue: fileUrl: !newVal", newVal)
          {
            await this.unloadDocument()
          }
        }
      },
    },

    emits: ["pdf-ready"],

    computed: {},

    created() {
      //credit: https://community.adobe.com/t5/document-services-apis/adobe-dc-view-sdk-ready/m-p/11648022#M948
      if (window.AdobeDC) this.pdfAPIReady = true
    },

    async mounted() {
      if (!this.pdfAPIReady) {
        const plugin = document.createElement("script")
        plugin.setAttribute("src", "https://documentservices.adobe.com/view-sdk/viewer.js")
        plugin.async = true
        document.head.appendChild(plugin)
        document.addEventListener("adobe_dc_view_sdk.ready", () => {
          this.pdfAPIReady = true
          this.$emit("pdf-ready", true)
          console.log("PDFViewer.vue: mounted: pdfAPIReady", this.pdfAPIReady)
        })
      }
    },

    methods: {
      async loadDocument() {
        let url = this.fileUrl
        
        const filePromise = new Promise(function (resolve, reject) {
          try {
            django_session
              .get(url, { responseType: "arraybuffer" })
              .then((res) => {
                // console.log("PDFViewer.vue: loadDocument: res.data", res.data)
                resolve(res.data)
              })
              .catch((error) => {
                reject(error)
                console.log("PDFViewer.vue: loadDocument: error", error)
              })
          } finally {
            console.log("PDFViewer.vue: loadDocument: finally")
          }
        })

        this.adobeDCView = new AdobeDC.View({
          clientId: process.env.VUE_APP_ADOBE_KEY,
          divId: "pdf-view",
        })
        let viewer = this.adobeDCView
        viewer
          .previewFile(
            {
              content: { promise: filePromise },
              metaData: {
                fileName: this.fileName,
              },
            },
            pdfPreviewConfig
          )
      },

      async unloadDocument() {
        console.log("PDFViewer.vue: unloadDocument")
        let viewer = document.getElementById("pdf-view")
        viewer.innerHTML = ""
        this.adobeDCView = null
        this.pdfAPIReady = false
      },
    },
  }
</script>

<style scoped>
  #pdf-view {
    height: calc(100vh - 300px);
  }
</style>
