<template>
  <div class="">
    <m-page-title title="Reports Home" />
    <div v-if="!showLoader"></div>
  </div>
</template>
<script>
  import { mapGetters } from "vuex"
  // import django_session from "@/api/django_session"

  export default {
    name: "ReportsHome",
    components: {},

    data() {
      return {
      }
    },

    computed: {
      ...mapGetters("auth", ["user", "roles", "permissions"]),
      ...mapGetters("common", ["showLoader"]),
    },

    created() {},

    async mounted() {
      document.title = "Reports Home"
    },

    methods: {


    },
  }
</script>
<style scoped></style>
